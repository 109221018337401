import { Box, Typography, Grid, ThemeProvider, autocompleteClasses } from "@mui/material";
import * as React from "react";
import { colorTheme } from "../const";
import NewGraduateImg from "../image/web-photo-05.webp";
import ContentsBox from "../components/ContentsBox";
import ButtonCircle from "../components/ButtonCircle";
import Slider from "../components/Slider";
import humanResourcesImg from "../image/IMG_1989.webp";
import openPositionsImg from "../image/IMG_2047.webp";
import Tag from "../components/Tag";
import Image from "../components/Image";
import ScrollToTopIcon from './../components/ScrollToTopIcon';
import { createTheme } from '@mui/material/styles';
import ContentsBoxWithTag from "../components/ContentsBoxWithTag";

export default function NewGraduateRecruitment(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  const theme = createTheme({
    typography: {
      fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
    },
  });
  return (
    <ThemeProvider theme={theme}>
            <Box className="App">
                <Box sx={{ backgroundColor: colorTheme.palette.creamGray.main }}>
                    {/* PC版 */}
                    <Box sx={{ display: { xs: "none", sm: "block", md: "block" } }}>
                        <div style={{ position: "relative", width: "100%", padding: "0px 10px 45% 0px" }}>
                            <img
                                src={process.env.PUBLIC_URL + NewGraduateImg}
                                width="70%"
                                height="100%"
                                alt="トップ画像"
                                style={{ position: "absolute", right: 0 }}
                            />
                            <div
                                style={{
                                    display: "inline-block",
                                    width: "35%",
                                    minWidth: "300px",
                                    height: "83%",
                                    backgroundColor: "white",
                                    position: "absolute",
                                    left: 0,
                                    top: 170,
                                    borderRadius: "0px 20px 20px 0px",
                                    whiteSpace: "pre-line",
                                    margin: "-140px 0 0 0",
                                }}
                            >
                              <Box sx={{ marginTop: "20px" }}> 
                                <Tag tagStyle="left" tagText="New Graduates" />
                              </Box>
                                <Box style={{ padding: "10px 5px 0px 50px", textAlign: "left" }}>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            padding: "2% 0 2% 0",
                                            fontWeight: "bold",
                                            fontSize: {
                                                xs: "20px",
                                                sm: "30px",
                                                md: "25px",
                                                lg: "2.2vw",
                                                xl: "2.4vw",
                                            },
                                            color: colorTheme.palette.navy.main,
                                        }}
                                    >
                                        {"新卒採用情報"}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            padding: { xs:"5px 0px", md:"30px 0px"},
                                            fontSize: {
                                                xs: "10px",
                                                sm: "13px",
                                                md: "15px",
                                                lg: "1.4vw",
                                                xl: "1.6vw",
                                            },
                                            color: colorTheme.palette.navy.main,
                                            lineHeight: "200%",
                                        }}
                                    >
                                        {'募集職種や採用担当からの\nメッセージなど\n新卒の方に向けた情報をまとめました。'}
                                    </Typography>
                                </Box>
                            </div>
                        </div>
                    </Box>
                    {/* スマホ版 */}
                    <Box sx={{ display: { xs: "block", sm: "none", md: "none", xl: "none" } }}>
                        <img
                            src={process.env.PUBLIC_URL + NewGraduateImg}
                            style={{ width: "100%" }}
                            alt="新卒採用のことTop画像"
                        />
                    </Box>
                </Box>
                {/* コンテンツ */}
                <Box sx={{
                    display: { xs: "block", sm: "none", md: "none" },
                    marginTop: "5%",
                    color: colorTheme.palette.navy.main,
                    backgroundColor: colorTheme.palette.creamGray.main,
                }}>
                    <ContentsBoxWithTag
                        tagStyle={{ float: "left" }}
                        tagText="New Graduates"
                        title="新卒採用情報"
                        text="募集職種や採用担当からのメッセージなど、新卒の方に向けた情報をまとめました。"
                    />
              </Box>
        <Box sx={{ width: "100%", backgroundColor: `${colorTheme.palette.creamGray.main}`, display: { xs: "none", sm: "block", md: "block" }, padding: "3% 0 3% 00%" }}>
          <Box >
            <ThemeProvider theme={colorTheme}>
              <Grid container spacing={1}>
                <Grid item sm={3} md={3}>
                  <Box
                    sx={{ padding: { sm: "7% 0 0 0%", md: "8% 0 0 10%", lg: "2% 0 0 15%", xl: "3% 0 0 10%" } }}
                    style={{
                      width: '35%',
                      minWidth: '310px',
                      left: 0,
                      position: 'absolute',
                      borderRadius: '20px 20px 20px 20px',
                      zIndex: 1
                    }}>
                    <img src={process.env.PUBLIC_URL + humanResourcesImg} width="80%" alt="トップサブ画像" style={{ borderRadius: '5%' }}></img>
                  </Box>
                </Grid>
                <Grid item sm={9} md={9}>
                  <Box style={{ textAlign: 'left', position: 'relative', width: '100%', padding: '5% 0% 2% 0', borderRadius: '20px 0px 0px 20px', backgroundColor: 'white', }}>
                    <Typography variant="h5" sx={{ fontSize: { xs: "28px", sm: "29px", md: "30px", lg: "2.5vw", xl: "2.8vw" } }} style={{ padding: '5% 0 5% 30%', textAlign: 'left', color: `${colorTheme.palette.darkGray.main}` }} fontWeight={'bold'}>{"コラボテクノは、「共に社会を創る仲間」を求めています。"}</Typography>
                    <Typography variant="h6" sx={{ fontSize: { xs: "16px", sm: "17px", md: "25px", lg: "1.5vw", xl: "1.8vw" } }} style={{ padding: '0 0 2% 30%', textAlign: 'left', color: `${colorTheme.palette.darkGray.main}` }} fontWeight={"bold"}>{"新卒採用担当 　熊澤 晴香"}</Typography>
                    <Typography variant="h6" sx={{ fontSize: { xs: "16px", sm: "17px", md: "25px", lg: "1.5vw", xl: "1.8vw" } }} style={{ padding: '0 2% 3% 30%', textAlign: 'left', color: `${colorTheme.palette.darkGray.main}` }} fontWeight={"light"}>{"コラボテクノは、「共に社会を創る仲間」を求めています。エンジニアを目指すといっても、コードや言語を綺麗に書くことは手段であっても目的ではありません。\n\nものづくりは、何のために行うのか、その目的を明確に持ち続け、共に会社を成長させていく仲間と一緒に働いて、夢を実現させていきたいと考えています!そんな静かなジョイ熱を燃やし続けるコラボテクノのメンバーと、一緒に成長していきたい。そんな想いに共感する人は、ぜひ一度お話ししましょう!"}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </ThemeProvider>
          </Box>
        </Box>
        <ThemeProvider theme={colorTheme}>
          <Box sx={{
            width: autocompleteClasses,
            height: autocompleteClasses,
            backgroundColor: 'inherit',
            whiteSpace: 'pre-line',
            alignItems: "left"
          }}>
            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ display: { xs: "inline-block", sm: "none", md: "none", xl: "none" } }}>
                <Box sx={{ position: "absolute" }}>
                  <Tag tagStyle={"left"} tagText={"Massage"}></Tag>
                </Box>
                <Image imageSrc={humanResourcesImg} width="100%" alt=""></Image>
              </Box>
              <Box sx={{ display: { xs: "block", sm: "none", md: "none", xl: "none" } }} style={{ padding: '10% 0 0 0', textAlign: 'left' }}>
                <Typography variant="h2" sx={{ fontSize: { xs: "28px", sm: "29px" } }} style={{ padding: '0px 0px 5% 5%', color: `${colorTheme.palette.darkGray.main}` }} fontWeight={'bold'}>{"コラボテクノは、\n「共に社会を創る仲間」を\n求めています。"}</Typography>
                <Typography variant="h5" sx={{ fontSize: { xs: "16px", sm: "17px" } }} style={{ padding: '0px 0px 5% 5%', color: `${colorTheme.palette.darkGray.main}` }} fontWeight={'bold'} >{"新卒採用担当 　熊澤 晴香"}</Typography>
                <Typography variant="h2" sx={{ width: "85%", fontSize: { xs: "16px", sm: "17px" } }} style={{ padding: '0px 2% 5% 5%', color: `${colorTheme.palette.darkGray.main}` }} fontWeight={"weight"}>{"ものづくりは、何のために行うのか、その目的を明確に持ち続け、共に会社を成長させていく仲間と一緒に働いて、夢を実現させていきたいと考えています！そんな静かな情熱を燃やし続けるコラボテクノのメンバーと、一緒に成長していきたい。そんな思いに共感する人は、ぜひ一度お話しましょう！"}</Typography>
              </Box>

            </Box>
          </Box>
        </ThemeProvider>
        <Box>
          <ContentsBox
            title={"新卒入社で活躍する社員の声"}
            text={
              "新卒で入社した先輩社員に、\n仕事のことや就活のことをインタビューしました。"
            }
          ></ContentsBox>
        </Box>
        <Box sx={{ padding: "5% 5% 5% 5%" }}>
          <Slider category="newEmployee" slidesToShow={3}></Slider>
        </Box>
        <Box sx={{ marginTop: "10%" }}>
          <img src={process.env.PUBLIC_URL + openPositionsImg} width="100%" alt="募集職種画像"></img>
          <ContentsBox
            title={"募集職種"}
            text={
              "コラボテクノのエンジニアとして自分の足で歩もうとする新卒の方のentryをお待ちしています。"
            }
          ></ContentsBox>
          <Box sx={{ paddingTop: "5%", paddingBottom: "5%" }}>
            <ButtonCircle
              url="/recruit/New-entry"
              text="エントリー"
            ></ButtonCircle>
          </Box>
        </Box>
        <ScrollToTopIcon />
      </Box>
    </ThemeProvider>
  );
}

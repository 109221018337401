import * as React from 'react';
import "../App.css";
import { Box, Grid, Typography } from "@mui/material";
import { colorTheme } from "../const";
import PageHeaderImage from "../components/PageHeaderImage";
import ContentsBox from "../components/ContentsBox";
import ButtonCircle from "../components/ButtonCircle";
import Tag from "../components/Tag";
import ImageSlider from "../components/Slider";
import ContentsBoxWithBottommAndImage from "../components/ContentsBoxWithBottomAndImage";
import WorkImg from "../image/IMG_0182.webp";
import WelfareImg from "../image/IMG_9911.webp";
import MidwayAdoptionImg from "../image/IMG_0819.webp";
import AdoptionImg from "../image/IMG_9973.webp";
import EmployeesTopImg from "../image/web-photo-01.webp";
import ScrollToTopIcon from './../components/ScrollToTopIcon';

const text1 = "コラボテクノで働くメンバーたち";

// 採用TOPページ
function RecruitTop(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  return (
    <div>
      <div className="App">
        <PageHeaderImage></PageHeaderImage>
        <br />
        <Box
          sx={{
            padding: "5% 0px 0px 0px",
            backgroundColor: `${colorTheme.palette.creamGray.main}`,
            paddingBottom: "5%",
            color: `${colorTheme.palette.navy.main}`,
          }}
        >
          <Box sx={{ padding: { xs: "0 0 0 0", md: " 0 0 0 5%" } }}>
            <Grid container spacing={1}>
              <Grid sm={5.5} md={6} lg={4} xl={6}>
                <ContentsBox
                  title={"Engineering Myself"}
                ></ContentsBox>
              </Grid>
              <Grid sm={6.5} md={5} lg={5} xl={6}>
                <ContentsBox
                  text={"自分らしく働き、自分で将来を描き、自分の生活を充実させていく。\nコラボテクノは、社員一人一人の成長と自己実現を第一に考える会社です。\nエンジニアとなって自分づくりを楽しみたい方、一緒に働きませんか。"}>
                </ContentsBox>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              color: `${colorTheme.palette.navy.main}`,
              marginTop: "5%",
              backgroundColor: "white",
              marginRight: "10%",
              borderBottomRightRadius: "5%",
              borderTopRightRadius: "5%",
            }}
          >
            <Tag tagStyle={"left"} tagText={"会社のこと"}></Tag>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6} xl={6} >
                <Box sx={{padding:"10% 0% 0 0%"}}>
                <ContentsBox
                  title={"私たちの想いと\nめざす未来"}
                  text={
                    "私たちコラボテクノがめざす\nVISIONとMISSION、\nそして会社のデータをご紹介します。"
                  }
                ></ContentsBox>
                <ButtonCircle
                    url="/recruit/company-info"
                    text="続きを読む"
                  />
                </Box>
              </Grid>

              <Grid item xs={0} sm={6} md={6} sx={{ display: { xs: "none", md: "block", xl: "block", sm: "block" }, marginTop: "5%" }}>
                <img
                  src={process.env.PUBLIC_URL + EmployeesTopImg}
                  width="95%"
                  alt="インタビュートップ画像"
                ></img>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box sx={{ padding: "20px 0px 30px 0px" ,backgroundColor: "white",}}>
          <Tag tagStyle={"left"} tagText={"社員のこと"}></Tag>
        </Box>
        <Box style={{backgroundColor: "white", textAlign: "left" }}>
          <Typography
            textAlign={"left"}
            sx={{backgroundColor: "white",
              paddingLeft: "20px",
              color: colorTheme.palette.navy.main,
              fontSize: { xs: "20px", sm: "20px", md: "30px", lg:"2vw", xl: "2vw" },
              fontWeight: "bold",
            }}
          >
            <div style={{ whiteSpace: "pre-line" }}>{text1}</div>
          </Typography>
        </Box>
        <Box sx={{ backgroundColor: "white",paddingTop: "2%", paddingBottom: "2%" }}>
          <ImageSlider category="memberAll" slidesToShow={3}></ImageSlider>
        </Box>
        <Box sx={{ backgroundColor: "white",padding: "0 0 1% 0" }}>
          <ButtonCircle url="/recruit/member" text="一覧表示"></ButtonCircle>
        </Box>
        <Box
          sx={{
            backgroundColor: `${colorTheme.palette.creamGray.main}`,
            paddingBottom: "5%",
            paddingTop: "5%",
          }}
        >
          <Box
            sx={{
              background: "white",
              padding: "0 0 3% 0",
              margin: "0px 3% 3% 0px",
              WebkitBorderTopRightRadius: "30px",
              borderBottomRightRadius: "15px",
              color: `${colorTheme.palette.navy.main}`
            }}
          >
            <Tag tagStyle={"left"} tagText={"仕事のこと"}></Tag>
            <ContentsBoxWithBottommAndImage
              url={"/recruit/work"}
              buttonText={"続きを読む"}
              imageSrc={WorkImg}
              title1={"私たちが提供する\n技術サービス"}
              subtext={
                "\nコラボテクノが展開する\n4つのビジネス・サービスについて、\n事業内容などをご紹介します。"
              }
            ></ContentsBoxWithBottommAndImage>
          </Box>
          <Box
            sx={{
              background: "white",
              padding: "0 0 3% 0",
              margin: "3% 0px 3% 3%",
              borderBottomLeftRadius: "15px",
              color: `${colorTheme.palette.navy.main}`
            }}
          >
            <Tag tagStyle={"right"} tagText={"福利厚生のこと"} sx={{ position: "absolute" }}></Tag>
            <ContentsBoxWithBottommAndImage
            url={"/recruit/benefits"}
              imageSrc={WelfareImg}
              buttonText={"続きを読む"}
              title1={"自分づくりを\nサポートする社内制度"}
              subtext={
                "\n産休育休はもちろん、社員の人生設計を応援する\nさまざまな社内制度をご紹介します。"
              }
            ></ContentsBoxWithBottommAndImage>
          </Box>
          <Box
            sx={{
              background: "white",
              padding: "0 0 3% 0",
              margin: "0px 3% 3% 0px",
              WebkitBorderTopRightRadius: "30px",
              borderBottomRightRadius: "15px",
              color: `${colorTheme.palette.navy.main}`
            }}
          >
            <Tag tagStyle={"left"} tagText={"新卒採用のこと"}></Tag>
            <ContentsBoxWithBottommAndImage
            url={"/recruit/new-graduates"}
              imageSrc={AdoptionImg}
              buttonText={"続きを読む"}
              title1={"はじめの一歩を\nしっかり応援"}
              subtext={
                "\nコラボテクノはスキルを身に着け、\n自分で人生を歩もうとする\n新卒の方のチャレンジをお待ちしています。"
              }
            ></ContentsBoxWithBottommAndImage>
          </Box>
          <Box
            sx={{
              background: "white",
              padding: "0 0 3% 0",
              margin: "3% 0px 3% 3%",
              borderBottomLeftRadius: "15px",
              color: `${colorTheme.palette.navy.main}`
            }}
          >
            <Tag tagStyle={"right"} tagText={"中途採用"}></Tag>
            <ContentsBoxWithBottommAndImage
            url={"/recruit/mid-career"}
              imageSrc={MidwayAdoptionImg}
              buttonText={"続きを読む"}
              title1={"自分らしく\n働ける環境を提供"}
              subtext={
                "\nエンジニアのスキルや実務経験のある方は\nもちろん、未経験の方も積極的に募集しています。"
              }
            ></ContentsBoxWithBottommAndImage>
            <Box sx={{ padding: { sm: "0 0 0 45%", md: "0 0 0 55%", xl: "0 0 0 50%" } }}>
            </Box>
          </Box>
        </Box>
      </div>
      <ScrollToTopIcon />
    </div>
  );
}

export default RecruitTop;

import { autocompleteClasses, Box, Typography, Grid } from '@mui/material';
import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { colorTheme } from '../const';
import ButtonCircle from "./ButtonCircle";

export default function ContentsBoxWithTagAndImage(props) {
  return (
    <ThemeProvider theme={colorTheme}>
      <Box sx={{
        width: autocompleteClasses,
        height: autocompleteClasses,
        backgroundColor: 'inherit',
        whiteSpace: 'pre-line',
        alignItems: "right"
      }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6} >
              <Box sx={{ padding: '2% 0 0 2%' }}>
                <img src={props.imageSrc} 
                  alt=""
                  width="98%"
                  height="400"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    display: "block",
                    margin: "0 auto"
                  }}
                  loading="eager"
                  fetchpriority="high"
                  ></img>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box style={{ width: { xs: "60%", sm: "70%", md: "100%",lg : "100%" ,xl: "100%" }, padding: '2% 0 0 2%', textAlign: 'left' }}>
                <Typography variant="h6" sx={{ fontSize: { xs: "18px", sm: "18px", md: "25px", lg:"2.1vw", xl: "2.4vw"  } }} style={{ padding: '0 0 0.5% 0', color: `${colorTheme.palette.navy.main}`,font : "Mincho" }} fontWeight={'bold'}>{props.title1}</Typography>
                <Typography variant="h6" sx={{ fontSize: { xs: "18px", sm: "18px", md: "25px", lg:"2.1vw", xl: "2.4vw"  } }} style={{ padding: '0 0 0.5% 0', color: `${colorTheme.palette.darkGray.main}`,font : "Mincho" }} fontWeight={'bold'}>{props.title2}</Typography>
                <Typography variant="h6" sx={{ fontSize: { xs: "10px", sm: "10px", md: "25px", lg:"1.3vw", xl: "1.6vw" } }} style={{ padding: '0 0 0.5% 0', color: `${colorTheme.palette.darkGray.main}`}}>{props.text1}</Typography>
                <Typography variant="h6" sx={{ fontSize: { xs: "10px", sm: "10px", md: "15px", lg:"1.3vw", xl: "1.6vw" } }} style={{ padding: '0 0 0.5% 0', color: `${colorTheme.palette.navy.main}`}} fontWeight={'bold'}>{props.text2}</Typography>
                <Typography variant="h6" sx={{ fontSize: { xs: "10px", sm: "10px", md: "15px", lg:"1.3vw", xl: "1.6vw" } }} style={{ padding: '0 0 0.5% 0',color: `${colorTheme.palette.darkGray.main}` }} fontWeight={"weight"}>{props.subtext}</Typography>
                <Box sx={{ width:"100%", padding: { xs: "0 0 0 0", sm: "10% 0% 0 0", md: "2% 0 0 0", lg: "0 0 0 0",xl: "3% 0 0 0" } }}>
                  <ButtonCircle
                    url={props.url}
                    text={props.buttonText}
                  ></ButtonCircle>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>

  )
}
